import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../firebase';
import { cloudFunction, CLOUD_FUNCTIONS } from '../firebase/callables';
import useDoc from '../hooks/useDoc';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

import { getNavItems, Route } from 'constants/routes';

export type CustomClaims = {
  roles: string[];
  regions: string[];
  teamId: string;
};
export interface IAppContextInterface {
  algoliaKeys: Record<string, string>;
  currentUser: firebase.default.User | null | undefined;
  profileDoc: Record<string, any>;
  profileDocState: any | null | undefined;
  updateProfileDoc: (data: any) => void;
  profileDocDispatch: (data: any) => void;
  userClaims: CustomClaims | undefined;
  navItems: Route[];
}

export const AppContext = React.createContext<IAppContextInterface>({
  algoliaKeys: {},
  currentUser: undefined,
  profileDoc: {},
  profileDocState: undefined,
  userClaims: undefined,
  updateProfileDoc: () => {},
  profileDocDispatch: () => {},
  navItems: [],
});
export default AppContext;

export const useAppContext = () => useContext(AppContext);

const getUserType = (roles: string[]) => {
  if (roles?.includes('TEAM')) return 'Team';
  if (roles?.includes('ADVISOR')) return 'Advisor';
  if (roles?.includes('FOUNDER')) return 'Founder';
  return 'Unknown';
};

export function AppProvider({ children }: React.PropsWithChildren<{}>) {
  const [currentUser, setCurrentUser] = useState<
    null | undefined | firebase.default.User
  >();
  const [userClaims, setUserClaims] = useState<CustomClaims>();
  const [profileDocState, profileDocDispatch, updateProfileDoc] = useDoc({});
  const [algoliaKeys, setAlgoliaKeys] = useState<Record<string, string>>({});

  const [navItems, setNavItems] = useState<Route[]>([]);
  useEffect(() => {
    const result = getNavItems({ userClaims, algoliaKeys });
    if (JSON.stringify(result) !== JSON.stringify(navItems)) {
      setNavItems(result);
    }
  // eslint-disable-next-line
  }, [userClaims, algoliaKeys, JSON.stringify(navItems)]);

  useEffect(() => {
    if (
      profileDocState.doc !== null &&
      profileDocState.doc !== undefined &&
      userClaims !== null &&
      userClaims !== undefined &&
      currentUser !== null &&
      currentUser !== undefined
    ) {
      posthog.identify(currentUser.uid, {
        name: currentUser.displayName,
        first_name: profileDocState.doc.firstName,
        last_name: profileDocState.doc.lastName,
        email: currentUser.email,
        Regions: profileDocState.doc.region,
        Cohort: profileDocState.doc.cohort,
        Role: getUserType(userClaims.roles || []),
        TrackedOut: profileDocState.doc.isTrackedOut,
        Funded: profileDocState.doc.isFunded,
        StartupIndustryFocus: profileDocState.doc.startupIndustryFocus,
        CountriesLivedIn: profileDocState.doc.countriesLivedIn,
        Education: profileDocState.doc.education,
        FounderType: profileDocState.doc.founderType,
        IndustryExperience: profileDocState.doc.industryExperience,
        IdeaToProgram: profileDocState.doc.ideaToProgram,
      });

      Sentry.setUser({
        name: currentUser.displayName,
        email: currentUser.email!,
        Regions: profileDocState.doc.region,
        Cohort: profileDocState.doc.cohort,
        Role: getUserType(userClaims.roles || []),
      });
    }
  }, [profileDocState.doc, userClaims, currentUser]);

  useEffect(() => {
    auth.onAuthStateChanged(async (auth) => {
      setCurrentUser(auth);

      if (auth) {
        window.analytics?.identify(auth.uid, {
          name: auth.displayName,
          email: auth.email,
        });

        const token = await auth.getIdTokenResult();
        setUserClaims(token?.claims as CustomClaims);
        if (token?.claims.roles?.includes('TEAM')) {
          profileDocDispatch({ path: `employees/${auth.uid}` });
        } else if (token?.claims.roles?.includes('INVESTOR')) {
          profileDocDispatch({ path: `investors/${auth.uid}` });
        } else if (token?.claims.roles?.includes('FOUNDER')) {
          profileDocDispatch({ path: `founders/${auth.uid}` });
        } 
      } else {
        posthog.reset();
      }
    });
  // eslint-disable-next-line
  }, []);

  const handleAlgoliaKeys = (data) => {
    setAlgoliaKeys(
      data?.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.indices?.reduce(
            (accIndices, currIndex) => ({
              ...accIndices,
              [currIndex]: curr.key,
            }),
            {}
          ),
        };
      }, {})
    );
  };

  useEffect(() => {
    if (profileDocState.doc) {
      if (profileDocState.doc && profileDocState.doc.algoliaKeys) {
        handleAlgoliaKeys(profileDocState.doc.algoliaKeys.keys);
      } else {
        console.log(`calling ${CLOUD_FUNCTIONS.getAlgoliaKeys}`);
        cloudFunction(
          CLOUD_FUNCTIONS.getAlgoliaKeys,
          {
            userGroup: profileDocState.path.split('/')[0],
          },
          (resp) => {
            handleAlgoliaKeys(resp.data.data);
          },
          (error) => console.log(error)
        );
      }
    }
  // eslint-disable-next-line
  }, [profileDocState.doc]);

  return (
    <AppContext.Provider
      value={{
        algoliaKeys,
        userClaims,
        navItems,
        currentUser,
        profileDoc: profileDocState.doc,
        profileDocState,
        updateProfileDoc,
        profileDocDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const tokenHasValue = async (key: string, value: unknown) => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdTokenResult();
    if (token.claims[key] && !token.claims[key].includes(value)) {
      user.getIdTokenResult(true);
    }
  }
};

export const refreshToken = () => {
  const user = auth.currentUser;
  if (user) user.getIdTokenResult(true);
};
