import { CustomClaims } from 'contexts/AppContext';

export enum routes {
  adminAuth = '/adminAuth',
  setLocation = '/setLocation',
  forgotPassword = '/forgotPassword',
  resetPassword = '/resetPassword',
  googleAuth = '/googleAuth',
  authLink = '/authLink',

  signIn = '/signIn',
  signUp = '/signUp',
  signOut = '/signOut',

  onboarding = '/welcome',

  home = '/',

  profile = '/profile',
  profilePersonal = '/profile/personal',
  profileFirm = '/profile/firm',

  followedStartups = '/followed-startups',
  allStartups = '/all-startups',
  startup = '/startup',
}

export type Route = {
  label: string;
  route: string;
  children?: {
    label: string;
    route: string;
    disabled?: boolean;
  }[];
  disabled?: boolean;
};

export type RouteFunctionProps = {
  userClaims?: CustomClaims;
  algoliaKeys?: Record<string, string>;
  userDoc?: Record<string, any>;
};
export type RouteFunction = (props: RouteFunctionProps) => Route | null;

export const canNav = (roles?: CustomClaims['roles']) =>
  roles && (roles?.includes('INVESTOR') || roles?.includes('TEAM'));

const home: RouteFunction = ({ userClaims }) => ({
  label: 'Home',
  route: routes.home,
  disabled: !canNav(userClaims?.roles),
});

const profile: RouteFunction = ({ userClaims }) => ({
  label: 'Profile',
  route: routes.profile,
  disabled: !canNav(userClaims?.roles),

  children: [
    { label: 'Personal', route: routes.profilePersonal },
    { label: 'Firm', route: routes.profileFirm },
  ],
});

const followedStartups: RouteFunction = ({ userClaims }) => ({
  label: 'Followed Startups',
  route: routes.followedStartups,
  disabled: !canNav(userClaims?.roles),
});

const allStartups: RouteFunction = ({ userClaims }) => ({
  label: 'All Startups',
  route: routes.allStartups,
  disabled: !canNav(userClaims?.roles),
});

export const getNavItems = (props: RouteFunctionProps): Route[] => {
  const ordered = [home, profile, followedStartups, allStartups];

  // Call all route functions
  const result: Route[] = [];
  ordered.forEach((fn) => {
    const res = fn.call(null, props);
    // Only display if not null
    if (res !== null) result.push(res);
  });

  return result;
};
