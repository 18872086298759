import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import _find from 'lodash/find';

import {
  makeStyles,
  createStyles,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import TopBarSubNav from './TopBarSubNav';

import { AppContext } from 'contexts/AppContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: { color: theme.palette.text.secondary },
    appBarScrolled: { boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset` },

    grid: {
      ...theme.mixins.toolbar,

      marginTop: 0,
      marginBottom: 0,
      '& > div': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
    },
  })
);

export interface ITopBarMobileProps {
  mobileNav: boolean;
  onDrawerToggle: () => void;
}

export default function TopBarMobile({
  mobileNav,
  onDrawerToggle,
}: ITopBarMobileProps) {
  const classes = useStyles();
  const location = useLocation();
  const { navItems } = useContext(AppContext);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const topLevelPath = '/' + location.pathname?.split('/')[1];
  const topLevelItem =
    _find(
      navItems,
      (item) => item.route.toLowerCase() === topLevelPath.toLowerCase()
    ) ??
    _find(
      navItems,
      (item) => item.route.toLowerCase() === location.pathname.toLowerCase()
    );

  useEffect(() => {
    if (!topLevelItem?.label || !topLevelItem)
      document.title = 'Antler Investor Platform';
    else if (!document.title.includes(topLevelItem!.label))
      document.title = `${topLevelItem!.label} | Antler Investor Platform`;
  }, [topLevelItem]);

  return (
    <AppBar
      position="sticky"
      className={clsx(classes.appBar, scrollTrigger && classes.appBarScrolled)}
      color="inherit"
      elevation={0}
    >
      <Container>
        <Grid
          container
          className={classes.grid}
          alignItems="center"
          spacing={1}
        >
          {mobileNav && (
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          )}

          <Grid item xs>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                component="h1"
                noWrap
                display="block"
                color="textPrimary"
              >
                {topLevelItem?.label}
              </Typography>
            </Toolbar>
          </Grid>

          {topLevelItem && topLevelItem?.children && (
            <TopBarSubNav topLevelItem={topLevelItem} />
          )}
        </Grid>
      </Container>
    </AppBar>
  );
}
