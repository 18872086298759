import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import clsx from 'clsx';
import { SwitchTransition } from 'react-transition-group';

import {
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Container,
} from '@material-ui/core';

import {
  Loading,
  ErrorBoundary,
  SlideTransition,
} from '@antlerengineering/components';
import TopBarMobile from './TopBarMobile';
import TopBarDesktop from './TopBarDesktop';
import NavSidebar from './NavSidebar';
import NPS from 'components/NPS';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      body: {
        overflowX: 'hidden',
      },
    },

    fullHeight: { height: '100%' },
    mainContainer: {
      '--section-margin': theme.spacing(6) + 'px',
      [theme.breakpoints.down('sm')]: {
        '--section-margin': theme.spacing(4) + 'px',
      },

      paddingTop: 'var(--section-margin)',
      paddingBottom: 'calc(var(--section-margin) + 56px)',
    },
  })
);

export default function Navigation({ children }: React.PropsWithChildren<{}>) {
  const classes = useStyles();
  const theme = useTheme();
  const mobileNav = useMediaQuery(theme.breakpoints.down('sm'));
  const fullHeight = use100vh();

  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleDrawerToggle = () => setSidebarOpen(!sidebarOpen);
  useEffect(() => {
    setSidebarOpen(false);
  }, [location.pathname]);

  return (
    <div style={{ minHeight: fullHeight ?? '100%' }}>
      {mobileNav && (
        <NavSidebar
          mobileNav={mobileNav}
          open={sidebarOpen}
          onCloseDrawer={handleDrawerToggle}
        />
      )}

      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={classes.fullHeight}
      >
        {mobileNav ? (
          <TopBarMobile
            mobileNav={mobileNav}
            onDrawerToggle={handleDrawerToggle}
          />
        ) : (
          <TopBarDesktop />
        )}

        <ErrorBoundary>
          <SwitchTransition>
            <SlideTransition
              key={location.pathname}
              className={classes.fullHeight}
            >
              <Container
                component="main"
                className={clsx(classes.fullHeight, classes.mainContainer)}
              >
                <Suspense fallback={<Loading />}>{children}</Suspense>
              </Container>
            </SlideTransition>
          </SwitchTransition>
        </ErrorBoundary>
      </Grid>

      <NPS />
    </div>
  );
}
