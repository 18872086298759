import { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  CssBaseline,
  MuiThemeProvider as ThemeProvider,
} from '@material-ui/core';
import Theme from './Theme';

import { AppProvider } from 'contexts/AppContext';
import CustomBrowserRouter from './utils/CustomBrowserRouter';
import SegmentPageTracker from 'utils/SegmentPageTracker';
import PrivateRoute from './utils/PrivateRoute';

import { SnackProvider } from 'samosas';
import { FiregridProvider } from 'contexts/FiregridContext';

import { routes } from './constants/routes';

import posthog from 'posthog-js';

import {
  ErrorBoundary,
  Loading,
  EmptyState,
} from '@antlerengineering/components';
import Navigation from './components/Navigation';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SignOutPage from './pages/AuthPages/SignOutPage';
import ForgotPasswordPage from 'pages/AuthPages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/AuthPages/ResetPasswordPage';
import AuthLinkPage from 'pages/AuthPages/AuthLinkPage';
import GoogleAuthPage from 'pages/AuthPages/GoogleAuthPage';
import SignInPage from 'pages/AuthPages/SignInPage';
import SignUpPage from 'pages/AuthPages/SignUpPage';

const AdminAuthPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "AdminAuthPage" */
      './pages/AuthPages/AdminAuthPage'
    )
);
const OnboardingPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "OnboardingPage" */
      './pages/OnboardingPage'
    )
);
const HomePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "HomePage" */
      './pages/HomePage'
    )
);
const ProfilePersonalPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ProfilePersonalPage" */
      './pages/ProfilePages/ProfilePersonalPage'
    )
);
const ProfileFirmPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ProfileFirmPage" */
      './pages/ProfilePages/ProfileFirmPage'
    )
);
const FollowedStartupsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "FollowedStartupsPage" */
      './pages/FollowedStartupsPage'
    )
);
const AllStartupsPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "AllStartupsPage" */
      './pages/AllStartupsPage'
    )
);
const StartupPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "StartupPage" */
      './pages/StartupPage'
    )
);

export default function App() {

  useEffect(() => {
    posthog.init("phc_z12oxx9EzDLiOPGYBHpsIkGa00VjdIpgxkkjkeFMIo5", {api_host: "https://app.posthog.com"});
  }, [])
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <ErrorBoundary>
        <AppProvider>
          <SnackProvider>
            <FiregridProvider>
              <DndProvider backend={HTML5Backend} context={window}>
                <CustomBrowserRouter>
                  <SegmentPageTracker />
                  <Suspense fallback={<Loading fullScreen />}>
                    <Switch>
                      <Route
                        exact
                        path={routes.adminAuth}
                        render={() => <AdminAuthPage />}
                      />
                      <Route
                        exact
                        path={routes.authLink}
                        render={() => <AuthLinkPage />}
                      />
                      <Route
                        exact
                        path={routes.googleAuth}
                        render={() => <GoogleAuthPage />}
                      />
                      <Route
                        exact
                        path={routes.forgotPassword}
                        render={() => <ForgotPasswordPage />}
                      />
                      <Route
                        exact
                        path={routes.resetPassword}
                        render={() => <ResetPasswordPage />}
                      />
                      <Route
                        exact
                        path={routes.signIn}
                        render={(props) => (
                          <SignInPage passwordAuth googleAuth {...props} />
                        )}
                      />
                      <Route
                        exact
                        path={routes.signUp}
                        render={() => <SignUpPage passwordAuth googleAuth />}
                      />
                      <Route
                        exact
                        path={routes.signOut}
                        render={() => <SignOutPage />}
                      />

                      <PrivateRoute
                        exact
                        path={routes.onboarding}
                        render={() => <OnboardingPage />}
                      />

                      <PrivateRoute
                        exact
                        path={routes.home}
                        render={() => (
                          <Navigation>
                            <HomePage />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.profile}
                        render={() => (
                          <Navigation>
                            <Redirect to={routes.profilePersonal} />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profilePersonal}
                        render={() => (
                          <Navigation>
                            <ProfilePersonalPage />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.profileFirm}
                        render={() => (
                          <Navigation>
                            <ProfileFirmPage />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        exact
                        path={routes.followedStartups}
                        render={() => (
                          <Navigation>
                            <FollowedStartupsPage />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.allStartups}
                        render={() => (
                          <Navigation>
                            <AllStartupsPage />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.startup}
                        render={() => (
                          <Navigation>
                            <Redirect to={routes.allStartups} />
                          </Navigation>
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={routes.startup + '/:id'}
                        render={(props) => (
                          <Navigation>
                            <StartupPage {...props} />
                          </Navigation>
                        )}
                      />

                      <PrivateRoute
                        render={() => (
                          <Navigation>
                            <EmptyState message="Page Not Found" />
                          </Navigation>
                        )}
                      />
                    </Switch>
                  </Suspense>
                </CustomBrowserRouter>
              </DndProvider>
            </FiregridProvider>
          </SnackProvider>
        </AppProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
