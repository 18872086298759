import { useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import queryString from 'query-string';

import { Typography, TextField, Button, Grid } from '@material-ui/core';
import AuthCard from './AuthCard';
import GoogleButton from './GoogleButton';

import { useSnackContext } from 'samosas';
import { handleGoogleAuth } from './utils';
import { auth, analytics } from '../../firebase';
import { CtaButton } from '@antlerengineering/components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ActivateInvestorAccount } from '../../firebase/callables';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'constants/routes';

export default function SignInPage({
  googleAuth = false,
  passwordAuth = false,
  location,
}: RouteComponentProps & {
  googleAuth?: Boolean;
  passwordAuth?: Boolean;
}) {
  const snack = useSnackContext();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState<'main' | 'email'>('main');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const parsedQuery = queryString.parse(window.location.search);
  const { profileDocDispatch } = useAppContext();
  
  const [inviteData, setInviteData] = useState({
    email: '',
    id: '',
    secretKey: '',
  });

  useEffect(() => {
    if (parsedQuery.invite) {
      setInviteData(JSON.parse(atob(parsedQuery.invite as string)));
    }
  }, [parsedQuery.invite]);

  const activateInvestorAccount = async (authResp) => {
    const resp = await ActivateInvestorAccount(
      inviteData.id,
      inviteData.secretKey
    );
    console.log({ resp });

    if (!resp.data.success) {
      snack.open({
        message: 'Failed to sign you up. Invalid invite',
        severity: 'error',
        duration: (null as unknown) as undefined,
      });
    }

    if (authResp.user) {
      const token = await authResp.user?.getIdTokenResult(true);
      if (token?.claims.roles?.includes('INVESTOR')) {
        profileDocDispatch({ path: `investors/${authResp.user.uid}` });
        window.location.replace(routes.onboarding);
      } else {
        snack.open({
          message: 'Failed to sign you up as an investor',
          severity: 'error',
          duration: (null as unknown) as undefined,
        });
      }
    }
  };

  const onSignInSuccess = (authUser: firebase.default.auth.UserCredential) => {
    if (!authUser) return;

    if(parsedQuery.invite){
      activateInvestorAccount(authUser)
      setLoading(false);
    }else{
      const parsed = queryString.parse(location.search);
      const redirect = parsed.redirect
        ? decodeURIComponent(parsed.redirect as string)
        : '/';

      analytics.logEvent('login', {
        method: authUser.credential?.signInMethod,
      });
      window.location.replace(redirect);
      setLoading(false);
    }
  };

  if (page === 'email')
    return (
      <>
        <div
          style={{ width: '100%', maxWidth: 420, margin: '50px auto -34px' }}
        >
          <Button
            startIcon={<ArrowBackIosIcon />}
            color="secondary"
            onClick={() => setPage('main')}
          >
            Sign In Options
          </Button>
        </div>

        <AuthCard height={400} loading={loading}>
          <Grid container spacing={3} wrap="nowrap" direction="column">
            <Grid item>
              <TextField
                fullWidth
                autoFocus
                label="Email Address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>

            <Grid item>
              <Button
                component={Link}
                to={
                  parsedQuery.invite
                  ?
                    `/forgotPassword?email=${inviteData.email}&invite=${parsedQuery.invite}`
                  :
                    `/forgotPassword?email=${inviteData.email}`
                }
                size="small"
              >
                Forgot password?
              </Button>
            </Grid>
          </Grid>

          <CtaButton
            size="medium"
            fullWidth
            onClick={() => {
              setLoading(true);
              auth
                .signInWithEmailAndPassword(email, password)
                .then(onSignInSuccess)
                .catch((error) => {
                  if (error.code === 'auth/wrong-password') {
                    snack.open({
                      message: `Incorrect password, or you might be using a Google account`,
                      action: (
                        <Button
                          color="inherit"
                          component="a"
                          href={
                            parsedQuery.invite
                            ?
                              `/forgotPassword?email=${inviteData.email}&invite=${parsedQuery.invite}`
                            :
                              `/forgotPassword?email=${inviteData.email}`
                          }
                        >
                          Forgot password
                        </Button>
                      ),
                      severity: 'error',
                      duration: 10_000,
                    });
                  } else {
                    snack.open({ message: error.message });
                  }
                })
                .finally(() => setLoading(false));
            }}
          >
            Sign in with Email
          </CtaButton>
        </AuthCard>
      </>
    );

  return (
    <AuthCard height={520} loading={loading}>
      <div>
        <Typography gutterBottom>
          If you have received an invitation through a Google account, please
          continue with <b>Sign in with Google</b>.
        </Typography>

        <Typography>
          Otherwise, you may start using Antler Investor Platform by clicking{' '}
          <b>Sign in with Email</b>.
        </Typography>
      </div>

      <div>
        <Typography variant="overline" paragraph>
          Google Authentication
        </Typography>
        <GoogleButton
          onClick={() => {
            setLoading(true);
            handleGoogleAuth(onSignInSuccess, (error) => {
              setLoading(false);
              snack.open({ message: error.message });
            });
          }}
        />
      </div>

      <div>
        <Typography variant="overline" paragraph>
          Don’t have a Google account?
        </Typography>
        <CtaButton
          size="medium"
          onClick={() => setPage('email')}
          variant="outlined"
          fullWidth
        >
          Sign in with Email
        </CtaButton>
      </div>
    </AuthCard>
  );
}
