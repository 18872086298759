import clsx from 'clsx';
import { Link } from 'react-router-dom';

import {
  makeStyles,
  createStyles,
  Drawer,
  Grid,
  Toolbar,
  IconButton,
  List,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import AntlerLogo from '@antlerengineering/components/src/assets/antler-logo.svg';
import NavSidebarItem from './NavSidebarItem';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'constants/routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflowX: 'hidden',
    },
    paper: {
      boxSizing: 'content-box',
      paddingLeft: 'env(safe-area-inset-left)',
      paddingRight: 'env(safe-area-inset-right)',
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',

      height: '100%',
    },

    grid: { height: `calc(100% + ${theme.spacing(1.5)}px)` },

    logoRow: {
      justifyContent: 'space-between',

      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    closeButton: {
      marginRight: theme.spacing(-1.5),
    },

    navList: {
      paddingTop: theme.spacing(2),
    },

    profilePhoto: {
      width: 24,
      height: 24,
    },

    signOutLi: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(1),
      textAlign: 'center',
    },
  })
);

export interface INavSidebarProps {
  mobileNav: boolean;
  open: boolean;
  onCloseDrawer: () => void;
}

export default function NavSidebar({
  mobileNav,
  open,
  onCloseDrawer,
}: INavSidebarProps) {
  const classes = useStyles();

  const { navItems } = useAppContext();

  return (
    <Drawer
      variant="temporary"
      open={!mobileNav || open}
      onClose={onCloseDrawer}
      classes={{
        root: classes.root,
        paper: clsx(classes.root, classes.paper),
      }}
      anchor="top"
      ModalProps={{ keepMounted: true }}
      PaperProps={{ elevation: 8 }}
    >
      <Grid
        container
        justify="space-between"
        direction="column"
        className={classes.grid}
        wrap="nowrap"
        spacing={3}
      >
        <Grid item xs component="nav" aria-label="main navigation">
          <Toolbar className={classes.logoRow}>
            <img
              src={AntlerLogo}
              alt="Antler Investor Platform"
              width="auto"
              height="24"
            />

            <IconButton
              onClick={onCloseDrawer}
              aria-label="Close Navigation"
              color="secondary"
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>

          <List className={classes.navList}>
            {navItems.map((item) => (
              <NavSidebarItem key={item.route} item={item} />
            ))}

            <li className={classes.signOutLi}>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to={routes.signOut}
                // className={classes.signOut}
              >
                Sign Out
              </Button>
            </li>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
}
