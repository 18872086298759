import React from 'react';

import { CtaButton } from '@antlerengineering/components';
import { ButtonProps } from '@material-ui/core';
import GoogleLogo from 'assets/google-icon.svg';

export default function GoogleButton(props: Partial<ButtonProps<any>>) {
  return (
    <CtaButton
      variant="outlined"
      fullWidth
      size="medium"
      startIcon={
        <img src={GoogleLogo} width={16} height={16} alt="Google logo" />
      }
      {...props}
    >
      {props.children || 'Sign in with Google'}
    </CtaButton>
  );
}
