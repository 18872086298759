import { IAppContextInterface } from 'contexts/AppContext';
import { db } from '../firebase';

export const firetableUser = (currentUser) => {
  const {
    displayName,
    email,
    uid,
    emailVerified,
    isAnonymous,
    photoURL,
  } = currentUser;
  return {
    timestamp: new Date(),
    displayName,
    email,
    uid,
    emailVerified,
    isAnonymous,
    photoURL,
  };
};

export function removeUndefined(obj: any) {
  Object.keys(obj).forEach((key) => {
    // Get this value and its type
    const value = obj[key];
    const type = typeof value;
    if (type === 'object') {
      // Recurse...
      removeUndefined(value);
    } else if (type === 'undefined') {
      // Undefined, remove it
      delete obj[key];
    }
  });

  return obj;
}

export const createDoc = (
  collection: string,
  data: any,
  currentUser: IAppContextInterface['currentUser']
) => {
  const docData = { ...removeUndefined(data) };
  if (currentUser) docData['_ft_createdBy'] = firetableUser(currentUser);
  docData['_ft_createdAt'] = new Date();

  return db.collection(collection).add(docData);
};
export const updateDoc = (
  path: string,
  data: any,
  currentUser: IAppContextInterface['currentUser']
) => {
  const docData = { ...removeUndefined(data) };
  if (currentUser) docData['_ft_updatedBy'] = firetableUser(currentUser);
  docData['_ft_updatedAt'] = new Date();

  return db.doc(path).update(docData);
};

export const handleTicketSizeUnits = (doc: Record<string, any>) => {
  const values = { ...doc };

  if (values.minCurrency === 'USD thousands') {
    values.min = values.min * 1000;
  }
  if (values.maxCurrency === 'USD thousands') {
    values.max = values.max * 1000;
  }

  return values;
};
