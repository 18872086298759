import { useLocation, Link } from 'react-router-dom';
import _findIndex from 'lodash/findIndex';

import { makeStyles, createStyles, Grid, Tabs, Tab } from '@material-ui/core';

import { Route } from 'constants/routes';

const useStyles = makeStyles((theme) =>
  createStyles({
    tabsContainer: {
      position: 'relative',

      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: -1,
        left: theme.spacing(0.5),
        right: theme.spacing(0.5),
        zIndex: -1,

        height: 1,
        backgroundColor: theme.palette.divider,
      },
      // boxShadow: `0 -1px 0 0 ${theme.palette.divider}`,
    },

    tabsFlexContainer: {
      justifyContent: 'flex-end',
    },

    tab: {
      ...theme.mixins.toolbar,

      fontFamily: theme.typography.h6.fontFamily,
      fontWeight: 'normal',
      fontSize: `${14 / 16}rem`,
      lineHeight: 16 / 14,
      letterSpacing: `${0.67 / 14}em`,
      textTransform: 'none',
    },
  })
);

export interface ITopBarSubNavProps {
  topLevelItem: Route;
}

export default function TopBarSubNav({ topLevelItem }: ITopBarSubNavProps) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid item xs={12} className={classes.tabsContainer}>
      <Tabs
        value={_findIndex(topLevelItem.children, (child) =>
          location.pathname.toLowerCase().startsWith(child.route.toLowerCase())
        )}
        aria-label="sub-navigation"
        // variant="scrollable"
        // scrollButtons="auto"
        classes={{ flexContainer: classes.tabsFlexContainer }}
        action={(actions) => setTimeout(() => actions?.updateIndicator(), 100)}
      >
        {topLevelItem.children!.map((child, index) => (
          <Tab
            key={child.route}
            label={child.label}
            component={Link}
            to={child.route}
            className={classes.tab}
            id={`sub-navigation-tab-${index}`}
            aria-controls={`sub-navigation-tabpanel-${index}`}
            disabled={topLevelItem.disabled || child.disabled}
          />
        ))}
      </Tabs>
    </Grid>
  );
}
