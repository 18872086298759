import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import {
  makeStyles,
  createStyles,
  useScrollTrigger,
  AppBar,
  Container,
  Grid,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import AntlerLogo from '@antlerengineering/components/src/assets/antler.svg';

import TopBarSubNav from './TopBarSubNav';

import { AppContext } from 'contexts/AppContext';
import { routes } from 'constants/routes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: { color: theme.palette.text.secondary },
    appBarScrolled: { boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset` },

    grid: {
      ...theme.mixins.toolbar,

      marginTop: 0,
      marginBottom: 0,
      '& > div': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
      },
    },

    endItem: { width: 100 },
    logo: { display: 'block' },

    tab: {
      ...theme.mixins.toolbar,

      fontFamily: theme.typography.h6.fontFamily,
      fontWeight: 'normal',
      fontSize: `${14 / 16}rem`,
      lineHeight: 16 / 14,
      letterSpacing: `${0.67 / 14}em`,
      textTransform: 'none',
    },

    signOut: {
      fontFamily: theme.typography.h6.fontFamily,
      fontWeight: 'normal',
      fontSize: `${14 / 16}rem`,
      lineHeight: 16 / 14,
      letterSpacing: `${0.67 / 14}em`,
      textTransform: 'none',

      minHeight: 32,
    },
  })
);

export default function TopBarDesktop() {
  const classes = useStyles();
  const location = useLocation();
  const { navItems } = useContext(AppContext);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const tabValue = _findIndex(
    navItems,
    (item) =>
      location.pathname.toLowerCase() === item.route.toLowerCase() ||
      location.pathname.toLowerCase().split('/')[1] ===
        item.route.toLowerCase().replace('/', '')
  );

  const topLevelPath = '/' + location.pathname?.split('/')[1];
  const topLevelItem =
    _find(
      navItems,
      (item) => item.route.toLowerCase() === topLevelPath.toLowerCase()
    ) ??
    _find(
      navItems,
      (item) => item.route.toLowerCase() === location.pathname.toLowerCase()
    );

  useEffect(() => {
    if (!topLevelItem?.label || !topLevelItem)
      document.title = 'Antler Investor Platform';
    else if (!document.title.includes(topLevelItem!.label))
      document.title = `${topLevelItem!.label} | Antler Investor Platform`;
  }, [topLevelItem]);

  return (
    <AppBar
      position="sticky"
      className={clsx(classes.appBar, scrollTrigger && classes.appBarScrolled)}
      color="inherit"
      elevation={0}
    >
      <Container>
        <Grid
          container
          className={classes.grid}
          alignItems="center"
          spacing={1}
        >
          <Grid item className={classes.endItem}>
            <Link to={routes.home}>
              <img
                src={AntlerLogo}
                alt="Antler Investor Platform"
                width="32"
                height="32"
                className={classes.logo}
              />
            </Link>
          </Grid>

          <Grid item xs>
            <Tabs
              value={tabValue > -1 ? tabValue : false}
              aria-label="navigation"
              action={(actions) =>
                setTimeout(() => actions?.updateIndicator(), 100)
              }
              centered
            >
              {navItems.map((item, index) => (
                <Tab
                  key={item.route}
                  label={item.label}
                  component={Link}
                  to={item.route}
                  id={`navigation-tab${index}`}
                  aria-controls={`navigation-tabpanel-${index}`}
                  className={classes.tab}
                  disabled={item.disabled}
                />
              ))}
            </Tabs>
          </Grid>

          <Grid item className={classes.endItem}>
            <Button
              variant="outlined"
              color="secondary"
              component={Link}
              to={routes.signOut}
              className={classes.signOut}
            >
              Sign Out
            </Button>
          </Grid>

          {/* <Grid item className={classes.endSpacer} /> */}

          {topLevelItem && topLevelItem?.children && (
            <TopBarSubNav topLevelItem={topLevelItem} />
          )}
        </Grid>
      </Container>
    </AppBar>
  );
}
