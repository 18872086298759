import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import { Typography } from '@material-ui/core';

import AuthCard from './AuthCard';
import GoogleButton from './GoogleButton';

import { handleGoogleAuth } from './utils';
import { useSnackContext } from 'samosas';
import { ActivateInvestorAccount } from '../../firebase/callables';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'constants/routes';

export default function GoogleAuthPage() {
  const [loading, setLoading] = useState(false);
  const snack = useSnackContext();
  const parsedQuery = queryString.parse(window.location.search);
  const { profileDocDispatch } = useAppContext();
  
  const [inviteData, setInviteData] = useState({
    email: '',
    id: '',
    secretKey: '',
  });

  useEffect(() => {
    if (parsedQuery.invite) {
      setInviteData(JSON.parse(atob(parsedQuery.invite as string)));
    }
  }, [parsedQuery.invite]);

  const activateInvestorAccount = async (authResp) => {
    const resp = await ActivateInvestorAccount(
      inviteData.id,
      inviteData.secretKey
    );
    console.log({ resp });

    if (!resp.data.success) {
      snack.open({
        message: 'Failed to sign you up. Invalid invite',
        severity: 'error',
        duration: (null as unknown) as undefined,
      });
    }

    if (authResp.user) {
      const token = await authResp.user?.getIdTokenResult(true);
      if (token?.claims.roles?.includes('INVESTOR')) {
        profileDocDispatch({ path: `investors/${authResp.user.uid}` });
        window.location.replace(routes.onboarding);
      } else {
        snack.open({
          message: 'Failed to sign you up as an investor',
          severity: 'error',
          duration: (null as unknown) as undefined,
        });
      }
    }
  };

  return (
    <AuthCard height={400} loading={loading}>
      <Typography variant="overline">Google Account</Typography>
      <Typography variant="body1">
        It looks like the following account was previously used to sign in with
        Google: <b>{parsedQuery.email}</b>
      </Typography>

      <GoogleButton
        onClick={() => {
          setLoading(true);
          handleGoogleAuth(
            (authUser) => {
              setLoading(false);
              if(parsedQuery.invite){
                activateInvestorAccount(authUser)
              }else{
                window.location.replace('/');
              }
            },
            (error: Error) => {
              setLoading(false);
              snack.open({ message: error.message });
            },
            parsedQuery.email as string
          );
        }}
      />
    </AuthCard>
  );
}
