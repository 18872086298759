import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import firebase from 'firebase/app';
import { makeStyles, createStyles } from '@material-ui/core';
import { differenceInMonths } from 'date-fns';

import { useAppContext } from 'contexts/AppContext';
import { useFiregridContext } from 'contexts/FiregridContext';
import { FormDialog } from '@antlerengineering/form-builder';
import { auth } from '../firebase';
import { createDoc } from 'utils';

const COOKIE_NAME = '_AIP_SESSION_START';

export const useTriggerNPS = () => {
  const { profileDoc, updateProfileDoc } = useAppContext();

  const triggerNPS = () => {
    if (!profileDoc) return false;

    const cookie = Cookies.get(COOKIE_NAME);
    if (
      !profileDoc.npsTriggerAfterSession ||
      differenceInMonths(
        new Date(),
        new Date(profileDoc.npsTriggerAfterSession)
      ) >= 3
    ) {
      updateProfileDoc({ npsTriggerAfterSession: cookie });
      return true;
    }

    return false;
  };

  return [triggerNPS];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      [theme.breakpoints.up('sm')]: { maxWidth: 464 },
      [theme.breakpoints.up('md')]: { maxWidth: 480 },
    },
  })
);

export default function NPS() {
  const classes = useStyles();

  const { search } = useLocation();
  const { currentUser, profileDocState, profileDoc, updateProfileDoc } =
    useAppContext();

  const { getForm } = useFiregridContext();
  const form = getForm('investors-nps');

  const [open, setOpen] = useState<boolean | number>(false);
  const [triggerChecked, setTriggerChecked] = useState(false);
  const [triggerNPS] = useTriggerNPS();

  // Open NPS based on URL
  useEffect(() => {
    const { nps } = queryString.parse(search);
    if (!nps) return;

    if (nps === 'true') {
      setOpen(true);
      updateProfileDoc({ npsLastTriggeredSession: cookie });
    } else {
      try {
        setOpen(Number(nps));
        updateProfileDoc({ npsLastTriggeredSession: cookie });
      } catch (e) {
        console.error('Could not convert NPS URL param to number');
      }
    }
  // eslint-disable-next-line
  }, [search]);

  const cookie = Cookies.get(COOKIE_NAME);

  // Open NPS based on Firestore trigger
  useEffect(() => {
    if (triggerChecked || !profileDoc || !cookie) return;

    const { npsTriggerAfterSession, npsLastTriggeredSession, npsTriggerEmail } =
      profileDoc;
    if (
      npsTriggerAfterSession < cookie &&
      (!npsLastTriggeredSession ||
        npsLastTriggeredSession <= npsTriggerAfterSession) &&
      !npsTriggerEmail
    ) {
      setOpen(true);
      updateProfileDoc({ npsLastTriggeredSession: cookie });
    }
    setTriggerChecked(true);
  // eslint-disable-next-line
  }, [profileDoc]);

  // Set session start cookie
  useEffect(() => {
    auth.onAuthStateChanged(async (auth) => {
      const sessionStart = cookie;
      const timestamp = new Date();

      // Set cookie with new session start, if non-existent or more than 24h ago
      if (
        !sessionStart ||
        new Date(sessionStart).getTime() <
          timestamp.getTime() - 1000 * 60 * 60 * 24
      )
        Cookies.set(COOKIE_NAME, timestamp.toISOString());
    });
  // eslint-disable-next-line
  }, []);

  // Write latest session start timestamp to user doc
  useEffect(() => {
    if (profileDocState.path && cookie) {
      const sessionStart = cookie;
      updateProfileDoc({
        npsSessions: firebase.firestore.FieldValue.arrayUnion(
          sessionStart || null
        ),
      });
    }
  // eslint-disable-next-line
  }, [profileDocState.path]);

  // Trigger NPS form after 3 sessions
  useEffect(() => {
    if (
      cookie &&
      profileDoc &&
      Array.isArray(profileDoc.npsSessions) &&
      profileDoc.npsSessions.length >= 3
    ) {
      const triggered = triggerNPS();
      if (triggered) updateProfileDoc({ npsSessions: [] });
    }
  // eslint-disable-next-line
  }, [profileDoc?.npsSessions]);

  if (!form || !open || !currentUser) return null;

  const handleSubmit = (values: Record<string, any>) => {
    createDoc(
      'npsInvestorPlatform',
      {
        uid: currentUser.uid,
        email: profileDoc.email,
        name: profileDoc.name,
        session: profileDoc.npsTriggerAfterSession,
        ...values,
      },
      currentUser
    );
  };
  const handleClose = (reason: 'submit' | 'cancel') => {
    if (reason === 'cancel') updateProfileDoc({ npsTriggerEmail: true });
    if (reason === 'submit') updateProfileDoc({ npsTriggerEmail: false });
    setOpen(false);
  };

  const fieldsWithCustomError = form.fields.map((field) => {
    if (field.name !== 'score') return field;
    return { ...field, validation: [['required', 'Please choose a score']] };
  });

  return (
    <FormDialog
      open
      values={typeof open === 'number' ? { score: open } : undefined}
      onClose={handleClose}
      onSubmit={handleSubmit}
      fields={fieldsWithCustomError}
      {...form.modal}
      DialogProps={{ PaperProps: { className: classes.paper } }}
    />
  );
}
