import { googleProvider } from '../../firebase';
import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { Typography, TextField, Divider, Button } from '@material-ui/core';
import {
  CtaButton,
  Modal,
  Loading,
  GoIcon,
} from '@antlerengineering/components';
import AuthCard from './AuthCard';
import { useSnackContext } from 'samosas';
import GoogleButton from './GoogleButton';
import { auth } from '../../firebase';
import { ActivateInvestorAccount, GetInvestorData } from '../../firebase/callables';
import { routes } from 'constants/routes';

import { useAppContext } from 'contexts/AppContext';
export default function SignUpPage({
  googleAuth = false,
  passwordAuth = false,
}: {
  googleAuth?: Boolean;
  passwordAuth?: Boolean;
}) {
  const snack = useSnackContext();

  const [inviteData, setInviteData] = useState({
    email: '',
    id: '',
    secretKey: '',
  });
  const { profileDocDispatch, currentUser, userClaims } = useAppContext();
  const { invite } = queryString.parse(window.location.search);
  useEffect(() => {
    if (invite) {
      try{
        setInviteData(JSON.parse(atob(invite as string)));
      }catch(e){
        async function fetchInvestorData() {
          const resp = await GetInvestorData(invite as string);
          if(resp.data.success){
            setInviteData(resp.data.investorData)
          }
        }
        fetchInvestorData()
      }
    }
  }, [invite]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [creatingUser, setCreatingUser] = useState(
    currentUser && !userClaims?.roles?.includes('INVESTOR')
  );

  const activateInvestorAccount = async (authResp) => {
    const resp = await ActivateInvestorAccount(
      inviteData.id,
      inviteData.secretKey
    );
    console.log({ resp });

    if (!resp.data.success) {
      snack.open({
        message: 'Failed to sign you up. Invalid invite',
        severity: 'error',
        duration: (null as unknown) as undefined,
      });
    }

    if (authResp.user) {
      const token = await authResp.user?.getIdTokenResult(true);
      if (token?.claims.roles?.includes('INVESTOR')) {
        profileDocDispatch({ path: `investors/${authResp.user.uid}` });
        window.location.replace(routes.onboarding);
      } else {
        snack.open({
          message: 'Failed to sign you up as an investor',
          severity: 'error',
          duration: (null as unknown) as undefined,
        });
      }
    }
  };
  const height: number =
    200 * (googleAuth ? 1 : 0) + 320 * (passwordAuth ? 1 : 0);
  return (
    <AuthCard height={height} loading={loading}>
      {passwordAuth && (
        <>
          <Typography variant="overline">Sign up with email</Typography>
          <Typography variant="body2">{inviteData.email}</Typography>
          <TextField
            label={'Password'}
            name={'password'}
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <TextField
            label={'Confirm Password'}
            name={'confirmPassword'}
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />

          <CtaButton
            fullWidth
            size="medium"
            onClick={async () => {
              try {
                if (password !== confirmPassword)
                  throw Error(`Passwords did not match`);
                if (password.length < 6) throw Error(`Password is too short`);
                setLoading(true);
                const authResp = await auth.createUserWithEmailAndPassword(
                  inviteData.email,
                  password
                );
                activateInvestorAccount(authResp);
              } catch (error) {
                console.log(error);
                if (error.code === 'auth/email-already-in-use' || error.message === 'The email address is already in use by another account.') {
                  auth
                  .signInWithEmailAndPassword(inviteData.email, password)
                  .then(activateInvestorAccount)
                  .catch((error) => {
                    if (error.code === 'auth/wrong-password') {
                      snack.open({
                        message: `An account linked to ${inviteData.email} already exists with different password, or you might be using a Google account`,
                        action: (
                          <Button
                            color="inherit"
                            component="a"
                            href={
                              invite
                              ?
                                `/forgotPassword?email=${inviteData.email}&invite=${invite}`
                              :
                                `/forgotPassword?email=${inviteData.email}`
                            }
                          >
                            Forgot password
                          </Button>
                        ),
                        severity: 'error',
                        duration: 20_000,
                      });
                    } else {
                      snack.open({ message: error.message });
                    }
                  })
                  .finally(() => setLoading(false));
                }else{
                  setLoading(false);
                  snack.open({ message: error.message, severity: 'error' });
                  if (error.code === 'auth/wrong-password') {
                    // snack.open({
                    //   message: `Incorrect password, or you might be using a Google account`,
                    //   action: <ForgotPasswordButton />,
                    // })
                  } else {
                    //  snack.open({ message: error.message })
                  }
                }
              }
            }}
          >
            SIGN UP WITH email
          </CtaButton>
        </>
      )}
      {googleAuth && passwordAuth && <Divider />}
      {googleAuth && (
        <>
          <Typography variant="overline">
            Do you have a Google account?
          </Typography>
          <GoogleButton
            onClick={async () => {
              setLoading(true);
              try {
                const authUser = await auth.signInWithPopup(googleProvider);
                activateInvestorAccount(authUser);
              } catch (error) {
                snack.open({ message: error.message, severity: 'error' });
              }
            }}
          >
            Sign up with Google
          </GoogleButton>
        </>
      )}

      {creatingUser && (
        <Modal
          onClose={() => setCreatingUser(false)}
          maxWidth="xs"
          title="Signing you up!"
          body={<Loading message="" />}
          footer={
            <Typography variant="body1" align="center">
              Email: {currentUser!.email}
            </Typography>
          }
          actions={{
            secondary: {
              children: 'Not you? Switch Account',
              endIcon: <GoIcon />,
              component: Link,
              to: routes.signOut,
            } as any,
          }}
        />
      )}
    </AuthCard>
  );
}
