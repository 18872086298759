import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';

import {
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      minHeight: 48,
      justifyContent: 'center',

      color: theme.palette.text.secondary,
      transition: theme.transitions.create(['color']),
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    listItemSelected: {
      '$listItem&': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        '&:hover': { backgroundColor: 'transparent' },
      },
    },
    listItemText: { flex: '0 0 auto' },

    childList: { paddingTop: 0 },
    childListItem: {
      minHeight: 40,
      height: 40,
    },
  })
);

export interface INavSidebarItemProps {
  item: any;
}

export default function NavSidebarItem({ item }: INavSidebarItemProps) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (!Array.isArray(item.children) || item.children.length === 0) return;
    const childItem = _find(
      item.children,
      (item) => item.route.toLowerCase() === location.pathname.toLowerCase()
    );
    if (!!childItem)
      document.title = `${childItem.label} | ${item.label} | Antler Investor Platform`;
  }, [location.pathname, item]);

  if (!Array.isArray(item.children) || item.children.length === 0)
    return (
      <li>
        <ListItem
          button
          selected={
            location.pathname.toLowerCase() === item.route.toLowerCase()
          }
          component={item.externalRoute ? 'a' : Link}
          to={!item.externalRoute ? item.route : undefined}
          href={item.externalRoute && item.route}
          target={item.externalRoute ? '_blank' : undefined}
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
          disabled={item.disabled}
        >
          <ListItemText
            primary={item.label}
            classes={{ root: classes.listItemText }}
            primaryTypographyProps={{ variant: 'h5' }}
          />
        </ListItem>
      </li>
    );

  return (
    <li>
      <ListItem
        button
        selected={location.pathname.toLowerCase() === item.route.toLowerCase()}
        component={item.externalRoute ? 'a' : Link}
        to={!item.externalRoute ? item.route : undefined}
        href={item.externalRoute && item.route}
        target={item.externalRoute ? '_blank' : undefined}
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        disabled={item.disabled}
      >
        <ListItemText
          primary={item.label}
          classes={{ root: classes.listItemText }}
          primaryTypographyProps={{ variant: 'h5' }}
        />
      </ListItem>

      <List className={classes.childList}>
        {item.children.map((childItem) => (
          <li key={childItem.route}>
            <ListItem
              button
              selected={location.pathname
                .toLowerCase()
                .startsWith(childItem.route.toLowerCase())}
              component={item.externalRoute ? 'a' : Link}
              to={!childItem.externalRoute ? childItem.route : undefined}
              href={childItem.externalRoute && childItem.route}
              classes={{
                root: clsx(classes.listItem, classes.childListItem),
                selected: classes.listItemSelected,
              }}
              disabled={item.disabled || childItem.disabled}
            >
              <ListItemText
                primary={'– ' + childItem.label}
                classes={{ root: classes.listItemText }}
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItem>
          </li>
        ))}
      </List>
    </li>
  );
}
