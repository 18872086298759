import { Route, RouteProps, Redirect } from 'react-router-dom';

import { useAppContext } from 'contexts/AppContext';
import { Loading } from '@antlerengineering/components';
import { routes } from 'constants/routes';

interface IPrivateRouteProps extends RouteProps {
  render: NonNullable<RouteProps['render']>;
}

export default function PrivateRoute({ render, ...rest }: IPrivateRouteProps) {
  const { currentUser, userClaims } = useAppContext();

  if (
    !!userClaims && 
    !userClaims.roles?.includes('INVESTOR') && 
    userClaims.roles?.includes('FOUNDER') && 
    rest.location?.pathname !== (routes.startup + "/" + userClaims?.teamId)
  ){
    window.location.replace(routes.startup + "/" + userClaims?.teamId)
  }

  if (!!currentUser) return <Route {...rest} render={render} />;

  if (currentUser === null)
    return (
      <Redirect
        to={
          routes.signIn +
          '?redirect=' +
          encodeURIComponent(
            rest.location
              ? rest.location.pathname +
                  rest.location.search +
                  rest.location.hash
              : ''
          )
        }
      />
    );

  return (
    <Route
      {...rest}
      render={() => <Loading message="Authenticating" fullScreen />}
    />
  );
}
